import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Layout } from './components/Layout';
import { 
  Home, 
  About, 
  WhoWeAre,
  HowDoesitWork,
  WhyChooseUs,
  FAQ,
  TreatmentDetail,
  PlasticSurgeries,
  DentalTreatments,
  BariatricSurgeries,
  PrivacyPolicy,
  EyeSurgeries,
  TermsAndConditions,
  CancellationAndRefund,
  Clinics,
  DoctorDetails,
  Blog,
  BlogDetails,
  Appointments,
  Departments,
  DepartmentDetails,
  PricingPlan,
  Gallery,
  Timetable,
  Contact,
  ThankYou,
  ErrorPage
 } from './pages';
// import { FloatingWhatsApp } from 'react-floating-whatsapp'; // Yorum satırına alındı

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="who-we-are" element={<WhoWeAre />} />
        <Route path="how-does-it-work" element={<HowDoesitWork />} />
        <Route path="why-choose-us" element={<WhyChooseUs />} />
        <Route path="faq" element={<FAQ />} />
        <Route path=":category/:subCategory/:detailId" element={<TreatmentDetail />} />
        <Route path="procedures" element={<TreatmentDetail />} />
        <Route path="procedures/plastic-surgeries" element={<PlasticSurgeries />} />
        <Route path="procedures/dental-treatments" element={<DentalTreatments />} />
        <Route path="procedures/bariatric-surgeries" element={<BariatricSurgeries />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="procedures/eye-surgeries" element={<EyeSurgeries />} />
        <Route path="terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="cancellation-and-refund" element={<CancellationAndRefund />} />
        <Route path="clinics" element={<Clinics />} />
        <Route path="doctors/:doctorId" element={<DoctorDetails />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog/:blogId" element={<BlogDetails />} />
        <Route path="appointments" element={<Appointments />} />
        <Route path="departments" element={<Departments />} />
        <Route path="departments/:departmentId" element={<DepartmentDetails />} />
        <Route path="pricing-plan" element={<PricingPlan />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="timetable" element={<Timetable />} />
        <Route path="contact" element={<Contact />} />
        <Route path="thank-you" element={<ThankYou />} />
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>

    {/* Floating WhatsApp yorum satırına alındı */}
    {/*
    <FloatingWhatsApp
      accountName="Medical Consultant - Western Health Turkey"
      phoneNumber="+905431919214"
      darkMode={false}
      chatMessage='Hello! How can we help you ?'
      avatar='/images/footer_logo_bg.png'
      statusMessage='Active'
    />
    */}

    {/* WhatsApp için tıklanabilir bağlantı - ikon ile */}
    <a
      href="https://wa.me/905431919208?text=Hello!%20I%20need%20assistance."
      target="_blank"
      rel="noopener noreferrer"
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        backgroundColor: '#25D366',
        color: '#fff',
        width: '70px',
        height: '70px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        textDecoration: 'none',
      }}
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
        alt="WhatsApp"
        style={{ width: '50px', height: '50px' }}
      />
    </a>
  </>
  );
}

export default App;
