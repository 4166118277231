import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; 
import ReCAPTCHA from 'react-google-recaptcha';

export default function DetailForm({ title }) {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        treatment: '',
        source: '',
        recaptchaToken: ''
    });
    const [errors, setErrors] = useState({});
    const [countryCode, setCountryCode] = useState('gb');
    const [captchaLoaded, setCaptchaLoaded] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('http://ip-api.com/json/')
            .then(response => {
                const country = response.data.countryCode.toLowerCase();
                setCountryCode(country);
            })
            .catch(error => {
                console.error('Error fetching location data:', error);
            });

        const script = document.createElement('script');
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        script.onload = () => setCaptchaLoaded(true);
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'formInputChange',
                fieldName: name,
                fieldValue: value,
            });
        }
    };

    const handlePhoneChange = (phone) => {
        setFormData({ ...formData, phone: phone });
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'formInputChange',
                fieldName: 'phone',
                fieldValue: phone,
            });
        }
    };

    const handleRecaptchaChange = (token) => {
        setFormData({ ...formData, recaptchaToken: token });
    };

    const validateForm = () => {
        const newErrors = {};
        for (const key in formData) {
            if (!formData[key] && key !== 'recaptchaToken' && key !== 'phone') {
                newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
            }
        }
        const phoneWithoutCountryCode = formData.phone.replace(`+${countryCode}`, '').trim();
        if (!formData.phone) {
            newErrors.phone = 'Phone is required';
        } else if (phoneWithoutCountryCode.length === 0) {
            newErrors.phone = 'Please enter a valid phone number';
        }
        if (!formData.recaptchaToken) {
            newErrors.recaptchaToken = 'Please verify that you are not a robot';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const formatPhoneNumber = (phone) => {
        if (phone && !phone.startsWith('+')) {
            return `+${phone}`;
        }
        return phone;
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (validateForm()) {
           
    
            const formattedPhone = formatPhoneNumber(formData.phone);
            const dataToSend = { ...formData, phone: formattedPhone };
    
            axios.post('https://www.westernhealthturkey.com/dashboard/submit.php', dataToSend, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                console.log(response.data);
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'formSubmission',
                    formData: dataToSend,
                    form_destination: "https://www.westernhealthturkey.com/thank-you", // Destination URL
                    gtm: {
                        elementUrl: "https://www.westernhealthturkey.com/thank-you", // Set the URL here
                    },
                });
                navigate('/thank-you');
            })
            .catch(error => {
                console.error('There was an error!', error);
                setErrors({ ...errors, submit: 'An error occurred. Please try again later.' });
            });
        }
    };

    return (
        <div className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30">
              <form onSubmit={handleSubmit} className="deneme">
              <div className="row">
                    {/* Name Field */}
                    <div className="col-lg-12">
                        <label className="cs_input_label cs_heading_color">Name</label>
                        <input
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            type="text"
                            className="cs_form_field"
                            placeholder="Your Name"
                            required
                        />
                        {errors.name && <p className="error-text">{errors.name}</p>}
                        <div className="cs_height_42 cs_height_xl_25" />
                    </div>

                    {/* Email Field */}
                    <div className="col-lg-12">
                        <label className="cs_input_label cs_heading_color">Email</label>
                        <input
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            type="email"
                            className="cs_form_field"
                            placeholder="Your Email"
                            required
                        />
                        {errors.email && <p className="error-text">{errors.email}</p>}
                        <div className="cs_height_42 cs_height_xl_25" />
                    </div>

                    {/* Phone Field */}
                    <div className="col-lg-12">
                        <label className="cs_input_label cs_heading_color">Phone</label>
                        <PhoneInput
                            country={countryCode}
                            value={formData.phone}
                            onChange={handlePhoneChange}
                            inputClass="cs_form_field"
                            required
                        />
                        {errors.phone && <p className="error-text">{errors.phone}</p>}
                        <div className="cs_height_42 cs_height_xl_25" />
                    </div>

                    {/* Source Field */}
                    <div className="col-lg-12">
                        <label className="cs_input_label cs_heading_color">How did you hear about us?</label>
                        <select
                            className="cs_form_field"
                            id="source"
                            name="source"
                            value={formData.source}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Choose</option>
                            <option value="Instagram">Instagram</option>
                            <option value="Facebook">Facebook</option>
                            <option value="Google">Google</option>
                            <option value="Referral">Referral</option>
                            <option value="Youtube">Youtube</option>
                        </select>
                        {errors.source && <p className="error-text">{errors.source}</p>}
                        <div className="cs_height_42 cs_height_xl_25" />
                    </div>

                    {/* reCAPTCHA */}
                    <div className="col-lg-12">
                        <ReCAPTCHA
                            sitekey="6LdmTzIqAAAAAOachFVNOYnK30jAi_12Dkl49yRf"
                            onChange={handleRecaptchaChange}
                        />
                        {errors.recaptchaToken && <p className="error-text">{errors.recaptchaToken}</p>}
                        <div className="cs_height_42 cs_height_xl_25" />
                    </div>

                    {/* Hidden Input for Treatment */}
                    <input type="hidden" id="treatment" name="treatment" value={formData.treatment} />

                    {/* Submit Button */}
                    <div className="col-lg-12">
                        <div className="cs_height_18" />
                        <button type="submit" className="cs_btn cs_style_1">
                            <span>Get a Free Quote</span>
                            <i>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18.416"
                                    height="13.153"
                                    viewBox="0 0 18.416 13.153"
                                >
                                    <path
                                        d="M973.04,2270.338l5.313,5.313-5.313,5.313"
                                        transform="translate(-962.923 -2269.631)"
                                        fill="none"
                                        stroke="#fff"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.5"
                                    />
                                </svg>
                            </i>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}
